import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Flex = makeShortcode("Flex");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Flex sx={{
      mt: [5, 6],
      flexDirection: 'column'
    }} mdxType="Flex">
      <Text sx={{
        fontSize: [4, 5, 6],
        fontWeight: `bold`,
        color: `heading`
      }} mdxType="Text">
  Hello 👋
      </Text>
      <p>{`I'm Scott - currently a Software Engineer in San Antonio, Texas 🌵. I love writing about React, Typescript, NodeJS, and Golang as well as creating
libraries and applications that help to make engineering easier and more accessible! I am currently a student at Georgia Tech pursuing
my Master of Science in Computer Science in their online program (OMSCS).`}</p>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      